@import './colorVariables.scss';

.opacity-50 {
    opacity: 0.5;
}

.bg-logo {
    background-color: $gray-200;
}

//specific width class for mobile view
.mobile-w-100 {

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        width: 100% !important; //override inline bootstrap class
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 100% !important; //override inline bootstrap class
    }
}

// .text-light is a class defined in bootstrap styling
.text-light-custom {
    color: $text-light;
}

.primary {
    color: $primary;
}

.secondary {
    color: $secondary;
}

/** Color **/
.grass-border {
    border: 1px solid $grass !important
}

.grass-color {
    color: $grass;
}

.grass-background {
    background-color: $grass;
}

.mint-background {
    background-color: $mint;
}

.eggplant-color {
    color: $eggplant;
}

.lake-color {
    color: $lake;
}

.river-color {
    color: $river;
}

.cedar-color {
    color: $cedar;
}

.cedar-border {
    border: 1px solid $cedar !important;
}

.forrest-color {
    color: $forrest;
}

.forrest-background {
    background: $forrest;
}

.spruce-color{
    color: $spruce;
}

.bone-background {
    background: $bone !important;
}

.stone-color{
    color: $stone !important;
}

.disable-color{
    color: $disable;
}

.light-grey-v-color{
    color: $light-grey-v;
}

.light-sage-color{
    color: $light-sage;
}

.light-sage-background{
    background-color: $light-sage;
}

.marigold-v-light-background{
    background: $marigold-v-light;
}

.marigold-v-color{
    color: $marigold-v;
}

/** Width **/
.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.default-theme-border-radius{
    border-radius: 15px !important;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.highlight {
    background-color: lightgrey;
}

.hidden {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}
  
.horizontal-scroller{
    overflow-x: scroll;
    white-space: nowrap;
}

.hide{
    opacity: 0;
}
  
.show{
    opacity: 1;
}

.border-medium {
    border-color: #555555;
}

.word-break-all {
    word-break: break-all;
}

.loading-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 1s;
}

.pointer-event-none{
    pointer-events: none;
}

.mark-required:after {
    content:" *";
}

.rounded-xl{
    border-radius: 0.4rem !important;
}

// Gray
.gray-200-bg {
    background: $gray-200;
}

/** Mixin **/
@mixin grey-form-control {
    background-color: $extra-light-grey !important;
    border-radius: 5px;
    border: 0;
}

@mixin tier-button-base {
    @include dog-plus-svg-fill-colors;
    border: 0 !important;
    font-weight: 600 !important;
    color: $bone;
}

@mixin grass-button {
    @include tier-button-base;
    background-color: $grass !important;

    &:hover{
        color: $eggplant;
        background-color: $light-sage !important;
    }
}

@mixin cedar-button {
    @include tier-button-base;
    background-color: $cedar !important;

    &:hover{
        color: $eggplant;
        background-color: $light-sage !important;
    }
}

@mixin river-button {
    @include tier-button-base;
    background-color: $river !important;

    &:hover{
        color: $eggplant;
        background-color: $light-sage !important;
    }
}

@mixin eggplant-button {
    @include tier-button-base;
    background-color: $eggplant !important;

    &:hover{
        color: $eggplant;
        background-color: $light-sage !important;
    }
}

@mixin dog-plus-button {
    @include tier-button-base;
    background-color: $light-sage !important;
    color: $stone;
    &:hover{
      background: $cedar !important;
      color: $bone !important;
    }
}

// TODO: keeping the bg image switching code here to serve buttons that are inside Django template, remove them when we no longer need those buttons inside Django template
@mixin dog-plus-button-bg-image {
    @include dog-plus-button;
    img {
        vertical-align: top;
    }
    .img-upgrade {
        background-image: url(/static/img/doge_plus.png);
        background-repeat: no-repeat;
        width: 20px;
    }
    &:hover{
        background: $cedar !important;
        color: $bone !important;

        .img-upgrade {
            background-image: url(/static/img/doge_plus_white.png);
        }
    }
}

@mixin dog-plus-svg-fill-colors {
    .dog-plus-svg-container{
        svg{
            path{
                &.bone-teal-lk{
                    fill: $bone;
                }

                &.marigold-v-teal-lk{
                    fill: $marigold-v;
                }

                &.teal-lk-bone, &.teal-lk-spruce{
                    fill: $teal-lk;
                }

                &.eggplant-marigold-v{
                    fill: $eggplant;
                }
            }
        }
    }

    &:disabled{
        pointer-events: none;
        .dog-plus-svg-container{
            svg{
                path{
                    &.bone-teal-lk{
                        fill: $bone;
                    }
    
                    &.marigold-v-teal-lk{
                        fill: $marigold-v;
                    }
    
                    &.teal-lk-bone{
                        fill: $teal-lk;
                    }
    
                    &.eggplant-marigold-v{
                        fill: $eggplant;
                    }
                }
            }
        }
    }

    &:hover{
        .dog-plus-svg-container{
            svg{
                path{
                    &.bone-teal-lk{
                        fill: $teal-lk;
                    }

                    &.marigold-v-teal-lk{
                        fill: $teal-lk;
                    }

                    &.teal-lk-bone{
                        fill: $bone;
                    }

                    &.eggplant-marigold-v{
                        fill: $marigold-v;
                    }

                    &.teal-lk-spruce{
                        fill: $spruce;
                    }
                }
            }
        }
    }
}

@mixin grey-badge {
    color: $bone !important;
    background: $disable;
}

/** Button style **/
.cedar-button{
    @include cedar-button
}