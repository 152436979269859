.data-source-container {
    .sample-data-icon {
        font-size: 2.5rem;
        vertical-align: text-top;
    }

    .site-walk-card {
        &.light-sage-background {
            :hover {
                color: #fff;
                background-color: $cedar !important;

                .dog-plus-svg-container {
                    svg {
                        path {
                            fill: $bone;
                        }
                    }
                }
            }
        }

        .card-header {
            .btn-tier-restriction {
                background-color: transparent !important;
            }
        }

    }

    .import-type-collapse {
        &.expanded {
            border: 2px solid $light-sage !important;
        }

        .import-type-icon {
            width: 1.5rem;
        }

        #spreadsheet-accordion {
            .source-type-radio-btn {
                label {
                    &.selected {
                        background-color: transparent !important;
                        border-color: $light-sage !important;
                        color: $eggplant !important;
                        border-width: 2px !important;
                    }
                }
            }
        }
    }
}